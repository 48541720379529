<template>
  <section class="app-main">
    <keep-alive>
      <transition :name="viewTransition" mode="out-in">
        <keep-alive>
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </keep-alive>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppMain',
  computed: {
    ...mapState({
      direction: state => state.app.direction
    }),
    key() {
      return this.$route.path
    },
    viewTransition() {
      if (!this.direction) return ''
      return 'van-slide-' + (this.direction === 'forward' ? 'left' : 'right')
    }
  }
}
</script>

<style lang="less" scoped>
.app-main {
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
