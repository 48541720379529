import axios from 'axios'
import qs from 'qs'
import { Notify } from 'vant'
import store from '@/store'
import { getToken, getUniacid } from '@/utils/auth'

// get uniacid
const uniacid = getUniacid()

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // 不加这个 we7 mobile下面会直接跳转错误
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (uniacid) {
      // 重组url，带上 uniacid
      config.url = '/app/index.php?i=' + uniacid + '&' + config.url

      // 携带 token
      if (store.getters.token) {
        config.headers['X-Token'] = getToken()
      }

      if (config.method === 'post') {
        config.data = qs.stringify({
          ...config.data
        })
      }

      return config
    } else {
      return Promise.reject(new Error('Error: uniacid null.'))
    }
  },
  error => {
    // do something with request error
    console.log('error', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const result = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (result.errno !== 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (result.errno === 50008 || result.errno === 50012 || result.errno === 50014) {
        // to re-login
        location.reload()
        // TODO:微信里不应该直接弹窗，暂时注释掉
        // Dialog.confirm({
        //   message: '请先登陆'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // }).catch(() => {
        //   window.history.back()
        // })
      }
      return Promise.reject(result)
    } else {
      return result
    }
  },
  error => {
    console.log('err' + error) // for debug
    Notify({
      type: 'warning',
      message: error.message,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
