import Vue from 'vue'

import '@/styles/index.less' // global css

import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import './icons'
import './permission' // permission control

// 处理点击事件延迟300ms问题
FastClick.attach(document.body)

import { Notify, Toast, Dialog } from 'vant'
Vue.use(Notify)
Vue.use(Toast)
Vue.use(Dialog)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
