import { getAppInfo } from '@/api/app'
import { getRule } from '@/api/data'
import { getBranch, setBranch } from '@/utils/auth'

const state = {
  device: 'desktop',
  inApp: 'browser',
  appInfo: {},
  direction: 'forward',
  branchInfo: {},
  branch: getBranch()
}

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_APP: (state, app) => {
    state.inApp = app
  },
  SET_APP_INFO: (state, appInfo) => {
    state.appInfo = appInfo
  },
  UPDATE_DIRECTION: (state, payload) => {
    state.direction = payload.direction
  },
  SET_BRANCH_INFO: (state, branchInfo) => {
    state.branchInfo = branchInfo
  }
}

const actions = {
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setApp({ commit }, app) {
    commit('SET_APP', app)
  },
  getAppInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getAppInfo().then(response => {
        const { data } = response
        commit('SET_APP_INFO', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getRule({ commit }, sysset_id) {
    return new Promise((resolve, reject) => {
      getRule({ 'sysset_id': sysset_id.trim() }).then(response => {
        const { data } = response
        commit('SET_BRANCH_INFO', data)
        setBranch(data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateDirection({ commit }, payload) {
    commit('UPDATE_DIRECTION', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
