<template>
  <van-tabbar safe-area-inset-bottom fixed placeholder route>
    <van-tabbar-item :to="{name:'home.index'}" icon="home-o">Home</van-tabbar-item>
    <van-tabbar-item :to="{name:'user.index'}" icon="user-o">User</van-tabbar-item>
    <van-tabbar-item :to="{name:'not.found'}" icon="info-o">Error</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  name: 'TabBar',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  }
}
</script>

<style scoped>

</style>
