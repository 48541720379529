import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout' // 为了首屏加载快，所以 Layout 不使用懒加载

/**
 * meta : {
    tabbarShow: true,             是否显示底部的 tabbar
    requireAuth: true             是否需要登陆验证
  }
 */

const routes = [
  { path: '/', redirect: '/404' },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: 'not.found'
  },
  {
    path: '/:uniacid/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    name: 'auth-redirect',
    meta: {
      title: '授权登录'
    }
  },
  {
    path: '/:uniacid',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index'),
        name: 'home.index',
        meta: {
          title: '首页',
          requireAuth: false,
          tabbarShow: false
        }
      },
      {
        path: 'get',
        component: () => import('@/views/get/index'),
        name: 'get.index',
        meta: {
          title: '取号',
          requireAuth: true,
          tabbarShow: false
        }
      },
      {
        path: 'number',
        component: () => import('@/views/number/index'),
        name: 'number.index',
        meta: {
          title: '排号详情页',
          requireAuth: true,
          tabbarShow: false
        }
      },
      {
        path: 'rule',
        component: () => import('@/views/rule/index'),
        name: 'rule.index',
        meta: {
          title: '规则',
          requireAuth: false,
          tabbarShow: false
        }
      },
      {
        path: '/close',
        component: () => import('@/views/error-page/close'),
        name: 'close.found',
        meta: {
          title: '提示'
        }
      },
      {
        path: 'agree',
        component: () => import('@/views/agree/index'),
        name: 'agree.index',
        meta: {
          title: '预约须知',
          requireAuth: false,
          tabbarShow: false
        }
      },
      {
        path: 'reserve',
        component: () => import('@/views/reserve/index'),
        name: 'reserve.index',
        meta: {
          title: '预约',
          requireAuth: false,
          tabbarShow: false
        }
      },
      {
        path: 'login',
        component: () => import('@/views/login/index'),
        name: 'user.login'
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404' }
]

// 自动扫描 modules 里面的路由模块，路由模块请根据业务自行拆分
const files = require.context('./modules', false, /\.js$/)

// 获取所有的路由内容
files.keys().forEach(key => {
  const file = files(key).default
  // 根据导出的内容判断是否数组，如果数组需使用扩展运算符
  if (Array.isArray(file)) {
    routes.push(...file)
  } else {
    routes.push(file)
  }
})

// Detail see: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const router = new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.afterEach(async(to, from) => {
  if (window.__wxjs_is_wkwebview) { // IOS
    if (window.entryUrl === '' || window.entryUrl === undefined) {
      // var url = baseLocation + to.fullPath
      window.entryUrl = location.href.split('#')[0]
      console.log('IOS', window.entryUrl)
    } else {
      console.log('IOS2', window.entryUrl)
    }
  } else { // 安卓
    window.entryUrl = location.href.split('#')[0]
  }
})

export default router
