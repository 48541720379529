const getters = {
  device: state => state.app.device,
  inApp: state => state.app.inApp,
  appInfo: state => state.app.appInfo,
  direction: state => state.app.direction,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  networkInfo: state => state.app.networkInfo
}
export default getters
