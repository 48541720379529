import request from '@/utils/request'

// 获取当前用户排号信息
export function getNumber(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=lineup',
    method: 'post',
    params: query
  })
}

// 获取当前用户排号信息状态
export function getNumberStatus(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=my_queue',
    method: 'post',
    params: query
  })
}

// 放弃当前排号
export function giveup(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=giveup',
    method: 'post',
    params: query
  })
}

// 排号规则
export function getRule(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=sysset',
    method: 'post',
    params: query
  })
}

// 获取车管所业务信息
export function getBusiness(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=get_business',
    method: 'get',
    params: query
  })
}

// 获取当前所选业务信息
export function getBusinessById(query) {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=get_business_item',
    method: 'get',
    params: query
  })
}
