import Cookies from 'js-cookie'

const TokenKey = '_csrf_uniacid_' + getUniacid()

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getBranch() {
  return Cookies.get('branch')
}

export function setBranch(data) {
  return Cookies.set('branch', data)
}

export function removeBranch() {
  return Cookies.remove('branch')
}

export function getUniacid() {
  const hashQuery = window.location.hash.match(/^#\/([\d]+)\//i)
  const historyQuery = window.location.href.match(/\/([\d]+)\//i)
  return hashQuery ? hashQuery[1] : historyQuery[1]
}
