import router from './router'
import store from './store'
// import {Message} from 'element-ui'
import { getToken, getUniacid } from '@/utils/auth'

// get uniacid
const uniacid = getUniacid()

// simple history management
const history = window.sessionStorage
history.clear()
let historyCount = history.getItem('count') * 1 || 0
history.setItem(`/${uniacid}/home`, 0)
let isPush = false
let isTouchStart = false
let endTime = Date.now()
const methods = ['push', 'go', 'replace', 'forward', 'back']

document.addEventListener('touchend', () => {
  isTouchStart = false
  endTime = Date.now()
})
document.addEventListener('touchstart', () => {
  isTouchStart = true
})
methods.forEach(key => {
  const method = router[key].bind(router)
  router[key] = function(...args) {
    isPush = true
    method.apply(null, args)
  }
})

router.beforeEach(async(to, from, next) => {
  // history management
  const toIndex = history.getItem(to.fullPath)
  const fromIndex = history.getItem(from.path)
  let direction

  if (toIndex) {
    if (!fromIndex || parseInt(toIndex, 10) > parseInt(fromIndex, 10) || (toIndex === '0' && fromIndex === '0')) {
      direction = 'forward'
    } else {
      direction = 'reverse'
    }
  } else {
    ++historyCount
    history.setItem('count', historyCount)
    to.fullPath !== `/${uniacid}/home` && history.setItem(to.fullPath, historyCount)
    direction = 'forward'
  }

  // 判断是否是ios左滑返回 或者 右滑前进
  if (toIndex && toIndex !== '0' && !isPush && (((Date.now() - endTime) < 377) || isTouchStart)) {
    await store.dispatch('app/updateDirection', { direction: '' })
  } else {
    await store.dispatch('app/updateDirection', { direction: direction })
  }
  isTouchStart = false

  // determine whether the user has logged in
  const hasToken = getToken()

  // 模块的全局基本信息
  const hasAppInfo = store.getters.appInfo && store.getters.appInfo.name
  if (!hasAppInfo) {
    await store.dispatch('app/getAppInfo')
    // await store.dispatch('user/getInfo')
  }

  // set page title
  document.title = store.getters.appInfo.index_title || to.meta.title

  if (hasToken) {
    if (to.fullPath === `/${uniacid}/login`) {
      // if is logged in, redirect to the number page
      next({ path: `/${uniacid}/home` })
    } else {
      const hasUserInfo = store.getters.userInfo.uid
      if (hasUserInfo) {
        next()
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo')
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          next(`/${uniacid}/login?redirect=${to.fullPath}`)
        }
      }
    }
  } else {
    /* has no token*/
    if (!to.meta.requireAuth) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/${uniacid}/login?redirect=${to.fullPath}`)
    }
  }
})
