import request from '@/utils/request'

// 返回模块全局基本信息
export function getAppInfo() {
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=render_data',
    method: 'get'
  })
}

// 获取微信 oauth 授权地址
export function getOauthUserInfoUrl(url) {
  url = encodeURIComponent(url)
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=oauth',
    method: 'get',
    params: { url }
  })
}

export function getJssdkConfig() {
  const url = encodeURIComponent(window.__wxjs_is_wkwebview ? window.entryUrl : window.location.href)
  return request({
    url: 'c=entry&m=lanniu_rownumber&do=wechat_jssdk',
    method: 'get',
    params: { url }
  })
}
