<template>
  <div :class="classObj" class="app-wrapper">
    <app-main />
    <tab-bar v-if="$route.meta.tabbarShow" />
  </div>
</template>

<script>
import { AppMain, TabBar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: {
    AppMain, TabBar
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      device: state => state.app.device
    }),
    classObj() {
      return {
        'body-desktop': this.device !== 'mobile'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/variables";
@import "~@/styles/mixin";

.app-wrapper {
  .clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
